.alert {
  background-color: $secondaryColor;
  padding: 8px;
  width: 60%;
  margin: 8px auto;
  font-size: 0.9em;
  display: flex;
  align-items: center;
  text-align: left;
  box-sizing: border-box;
  color: white;

  &--unvisible {
    display: none;
  }

  @media #{$mini_tablet} {
    width: 100%;
  }

  &__icon {
    font-size: 1rem;
    margin-right: 8px;

    display: none;
  }

  &--info {
    .fa-info-circle {
      display: block;
    }
  }

  &--error {
    background-color: firebrick;
    .fa-exclamation-circle {
      display: block;
    }
  }

  &--warning {
    background-color: orange;
    .fa-exclamation-circle {
      display: block;
    }
  }

  &--success {
    background-color: green;
    .fa-check-circle {
      display: block;
    }
  }
}
