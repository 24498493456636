// Index
.calendar-wrapper {
  .cal-buttons {
    display: grid;
    grid-gap: 16px;
    padding: 16px;
    grid-template-columns: repeat(3, 1fr);

    @media #{$mini_tablet} {
      grid-template-columns: 1fr 1fr;
      grid-gap: 8px 0;
      padding: 8px;

      > a {
        grid-column: 1/3;
        grid-row: 1/2;
      }
    }

    @media #{$phone} {
      font-size: 0.8em;
      padding: 0;
    }
  }

  > .button {
    @include margin-auto;
    margin-top: 20px;
    width: 200px;
  }
}

.calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: 30px;
  grid-auto-rows: 120px;
  margin-bottom: 10px;

  @media #{$tablet} {
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    grid-template-rows: auto;
  }

  &__header {
    text-align: center;
    font-variant: small-caps;
    font-style: italic;
    color: #666;
    padding: 6px;
    border-bottom: solid 1px #ddd;

    @media #{$tablet} {
      display: none;
    }
  }

  &__day {
    padding: 6px;
    background-color: #fff;
    transition: 0.3s;
    border-bottom: solid 1px #eee;

    &:hover {
      background-color: #fafafa;
    }

    &:empty:hover {
      background-color: #fff;
    }

    b {
      text-align: left;
      font-size: 1.3em;
      color: $primaryColor;
      font-weight: 300;
      margin: 0;
    }

    @media #{$tablet} {
      min-height: 48px;

      &:empty {
        display: none;
      }
    }

    &--today {
      border: solid 1px $primaryColor;
      border-top: solid 4px $primaryColor;
    }
  }

  &__events {
    height: calc(100% - 18px);
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  &__event {
    display: block;
    font-size: 0.6em;
    padding: 4px;
    color: #fff;
    text-transform: uppercase;
    background-color: #666;
    text-decoration: none;
    transition: 0.3s;
    margin: 4px 0;

    &:hover {
      background-color: #666 * 1.1;
    }

    &--default {
      background-color: $primaryColor;
      &:hover {
        background-color: $primaryColor * 1.1;
      }
    }

    &--orange {
      background-color: #ca670a;

      &:hover {
        background-color: #ca670a * 1.1;
      }
    }

    &--green {
      background-color: #14bb38;

      &:hover {
        background-color: #14bb38 * 1.1;
      }
    }

    &--blue {
      background-color: #4173b1;

      &:hover {
        background-color: #4173b1 * 1.1;
      }
    }

    &--purple {
      background-color: #4b226e;

      &:hover {
        background-color: #4b226e * 1.1;
      }
    }
  }
}

// Wydarzenie
// .basic-grid

// Aside
.event-list {
  &__item {
    display: grid;
    grid-template-columns: 80px 1fr;
    padding: 5px;
    border: solid 1px $primaryColor;
    margin-bottom: 5px;
    text-decoration: none;
    grid-gap: 5px;
    transition: background-color 0.3s;

    span {
      text-align: center;
      @extend .flexi;
      color: #666;
      font-size: 13px;
    }

    span:last-of-type {
      color: $primaryColor;
      font-weight: $boldFont;
      font-size: 16px;
    }

    &:hover {
      background-color: #eee;
    }
  }
}
