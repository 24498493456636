.content.settings {
  grid-template-columns: 320px 1fr;
}

.settings-list {
  display: flex;
  flex-direction: column;
  padding-top: 16px;

  button {
    display: block;
    background-color: #fff;
    border: 0;
    outline: none;
    padding: 8px;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      background-color: #eee;
    }

    &:active {
      background-color: #ddd;
    }

    &.active {
      background-color: $primaryColor;
      color: white;
    }
  }
}

.settings-box {
  position: relative;
  min-height: 500px;

  &__item {
    display: block;
    box-sizing: border-box;
    padding: 10px;
    background-color: #fff;

    > img {
      display: block;
      @include round-img(260px);
      @include margin-auto;
    }

    h2 {
      text-align: center;
      font-variant: small-caps;
      font-size: 28px;
    }

    > p {
      color: $primaryColor;
      font-style: italic;
      text-align: center;
      margin-top: -8px;
    }
  }

  @media #{$tablet} {
    .form__group {
      width: 100%;
    }
  }
}

.sum-list {
  p {
    border-bottom: solid 1px #888;
    padding: 8px;
    margin: 0;
    display: flex;
    color: $primaryColor;

    span {
      color: #888;
      font-weight: $boldFont;
      width: 100px;
      display: block;
    }

    &:nth-of-type(even) {
      background-color: #f5f5f5;
    }

    &:last-of-type {
      border: 0;
    }
  }

  &.adds {
    width: 300px;
    @include margin-auto;

    @media #{$mini_phone} {
      width: 100%;
    }
  }
}

.delete-account {
  p {
    color: #aaa;
    font-style: italic;
    margin: 10px 20px;
  }
}

.change-profile-pic {
  display: flex;
  justify-content: center;
  align-items: center;

  label {
    display: block;
    position: relative;
    @include round-img(260px);

    &::before {
      @include pseudo-fontawesome;
      @include flex-center;
      content: '\f093';
      position: absolute;
      background-color: #3333;
      color: #fff;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      font-size: 3rem;
      opacity: 0;
      transition: 0.3s;
      cursor: pointer;
    }

    &:hover::before {
      opacity: 1;
    }
  }

  img {
    display: block;
    @include round-img(260px);
    @include margin-auto;
  }

  input {
    display: none;
  }
}

.friend {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border-bottom: dotted 1px #aaa;
  
  &:first-of-type {
    border-top: dotted 1px #aaa;
  }
  
  img {
    @include round-img(32px);
    margin-right: 12px;
  }
  
  span,
  a {
    text-decoration: none;
    font-variant: small-caps;
    color: $primaryColor;
  }

  .del {
    margin-left: auto;
  }
}