footer {
  background-color: $secondaryColor;
  padding: 10px;
  display: flex;
  justify-content: center;
  padding: 36px 0;

  .cont,
  ol {
    width: 550px;
  }

  .cont {
    margin-top: auto;

    img {
      height: 50px;
    }
    p {
      color: #889;
      font-size: 0.8em;

      a {
        color: #99a;
        text-decoration: none;
      }
    }
  }

  hr {
    border: 0;
    border-top: solid 1px #556;
    width: 70%;
    display: inline-block;
  }

  ol {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin: 2px 0;
      width: 200px;
      text-align: right;
      margin-left: auto;

      a {
        padding: 7px;
        display: block;
        border-radius: 5px;
        color: #888;
        transition: background-color 0.3s;
        text-decoration: none;

        &:hover {
          background-color: $secondaryColor * 1.3;
        }
      }
    }
  }
}

@media #{$desktop} {
  footer {
    display: flex;
    padding: 22px 10px;
  }
}

@media #{$mini_tablet} {
  footer {
    flex-direction: column-reverse;

    .cont,
    ol {
      width: 100%;
    }
  }
}

@media #{$mini_phone} {
  footer img {
    height: auto !important;
    width: 80%;
  }
}
