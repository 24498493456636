.breadcrumps {
  width: $cont_width;
  margin: 0 auto;
  background-color: transparent;
  display: flex;
  margin-bottom: 12px;
  padding: 0;
  list-style-type: none;
  font-variant: small-caps;

  &--margined {
    margin-top: 12px;
  }

  @media #{$wide_screen} {
    width: calc(100% - 360px);
    box-sizing: border-box;
    padding: 0 8px;
  }

  @media #{$desktop} {
    width: 100%;
  }

  @media #{$phone} {
    display: none;
  }

  li:not(:last-of-type)::after {
    box-sizing: border-box;
    content: '>';
    margin: 0 4px;
    color: #aaa;
    width: 16px;
    height: 16px;
    padding-left: 3px;
    display: inline-block;
  }

  a {
    color: $primaryColor;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}