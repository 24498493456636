.he-ad-container,
.he-ad a {
  max-width: 100%;
  max-height: 100%;
}

.he-ad:empty {
  display: none !important;
}

.he-ad a {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  height: 100%;
}

.center-add,
.center-add img,
.he-ad[data-type=midi],
.he-ad[data-type=midi] img,
.he-ad[data-type=top],
.he-ad[data-type=top] img,
.he-ad[data-type=mega-top],
.he-ad[data-type=mega-top] img  {
  display: block;
  max-width: 100%;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  object-fit: cover;
}

.he-ad[data-type=midi],
.he-ad[data-type=top],
.he-ad[data-type=mega-top] {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-add,
.he-ad[data-type=midi],
.he-ad[data-type=top],
.he-ad[data-type=mega-top]  {
  height: 0;
  box-sizing: border-box;
  padding-bottom: 100/750 * 100%;
  max-width: 700px;
  max-height: 100px;
  position: relative;
}

.center-add img
.he-ad[data-type=midi] img,
.he-ad[data-type=top] img,
.he-ad[data-type=mega-top] img {
  position: absolute;
  top: 0;
  left: 0;
}

.content-add,
.he-ad[data-type=box] {
  display: block;
  margin: 0 auto;
  height: 200px;
  width: 300px;
  position: relative;
  box-sizing: border-box;
  max-width: 300px;
  max-height: 200px;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 300px;
    max-height: 200px;
    @include center('horizontal');
  }

  @media screen and (max-width: 320px) {
    width: 100%;
    height: 0;
    padding-top: 200/300 * 100%;
  }
}

.left-add-wrapper,
.right-add-wrapper {
  position: fixed;
  top: calc(50% - 300px);
  width: 160px;
  margin-left: 10px;
  margin-right: 10px;

  .center-add,
  .he-ad {
    width: 160px !important;
    height: 600px !important;
    max-width: 160px;
    max-height: 600px;
  }

  &.static {
    position: absolute;
    top: auto;
    bottom: 290px;
  }

  &.top {
    position: absolute;
    top: 160px;
  }
}

.top-add {
  width: 700px;
  margin: auto;

  &--margin {
    margin: 10px auto;
  }

  .center-add {
    padding-top: 100/750 * 100%;
    max-width: 700px;
    max-height: 100px;
  }

  @media (max-width: 700px) {
    width: 100%;
  }
}

.left-add-wrapper {
  left: calc((50vw - (#{$cont_width}/ 2)) / 2);
  transform: translateX(-50%);
}

.right-add-wrapper {
  right: calc((50vw - (#{$cont_width}/ 2)) / 2);
  transform: translateX(50%);
}

@media #{$wide_screen} {
  .left-add-wrapper {
    left: 0;
    transform: translateX(0);
  }

  .right-add-wrapper {
    right: 0;
    transform: translateX(0);
  }
}

@media #{$desktop} {
  .left-add-wrapper,
  .right-add-wrapper {
    display: none;
  }
}
