#aside-nav {
  position: fixed;
  top: 0;
  right: -320px;
  width: 320px;
  height: 100vh;
  background-color: white;
  z-index: 1001;
  box-sizing: border-box;
  padding: 10px;
  overflow-y: auto;
  transition: right 0.3s;
  transition: right 0.3s;

  &::-webkit-scrollbar {
    width: 4px;
    background-color: #eee;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $primaryColor;
  }

  &.online {
    right: 0;
  }
  h3 {
    color: $primaryColor;
    text-align: center;
    font-variant: small-caps;
    margin-top: 20px;
    margin-bottom: 10px;

    &:first-of-type {
      margin-top: 10px;
    }
  }
  .search-group {
    width: 100%;
    border: solid 1px #aaa;
    border-radius: 7px;
    outline: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    input {
      width: 100%;
      padding: 7px;
      border: 0;
      outline: 0;
    }
    button {
      width: 36px;
      padding: 5px;
      background-color: #aaa;
      border: 0;
      outline: 0;
      color: white;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #888;
      }

      img {
        height: 22px;
        display: block;
      }
    }
  }
  .buttins {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // Zalogowany
  > img {
    margin-top: 20px;
    display: block;
    @include margin-auto;
    @include round-img(120px);

    & + h3 {
      margin-top: 4px;
    }
  }

  .username {
    text-align: center;
    margin: 0;
    color: $primaryColor * 0.8;
    font-variant: small-caps;
  }

  > .button {
    @include margin-auto;
    width: 140px;
    margin-top: 10px;
    transform: scale(0.9);
  }

  .options {
    transform: scale(1);
    padding-left: 22px;
    position: relative;

    &::before {
      content: '';
      width: 22px;
      height: 22px;
      position: absolute;
      top: 0;
      left: 4px;
      background-image: url('/img/icons/account.svg');
      background-size: cover;
      background-position: center;
      @include center('vertical');
    }
  }

  // Nawigacja
  ol {
    list-style: none;
    padding: 0;
    margin: 0;
    a {
      display: block;
      padding: 7px;
      font-variant: small-caps;
      color: $primaryColor;
      text-decoration: none;
      transition: padding-left 0.3s, background-color 0.3s;
      border-radius: 7px;

      &:hover {
        padding-left: 10px;
        background-color: #eee;
      }
    }
  }
}

.background {
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100% + 300px);
  height: 100%;
  background-color: #333a;
  transform: translateX(300px);
  z-index: 1000;
  transition: opacity 0.3s;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;

  &.online {
    opacity: 1;
    visibility: visible;
  }
}

@media #{$phone} {
  #aside-nav {
    width: 80vw;
    right: -80vw;
  }
}
