.form input:not([type='checkbox']),
.form select,
.form textarea {
  padding: 4px;
  background-color: white;
  border: solid 1px #aaa;
  border-radius: 5px;
  width: 90%;
  box-sizing: border-box;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;

  &::-moz-focusring,
  &:-moz-focusring,
  &::-moz-focus-inner {
    color: transparent !important;
    text-shadow: 0 0 0 #000 !important;
    background-image: none !important;
    border: 0;
  }

  &:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
  }

  option:not(:checked) {
  color: black;
  }

  &:not(:checked) {
    color: black;
  }
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;

  &--cool {
    input:not([type='checkbox']),
    textarea,
    select {
      border: 0 !important;
      border-bottom: solid 2px #bbb !important;
      border-radius: 0;
      transition: 0.3s !important;

      &:focus {
        border-color: $primaryColor !important;
      }
    }
  }
}

.form__group {
  position: relative;
  margin-top: 26px;
  width: 60%;

  @media #{$mini_tablet} {
    grid-column: 1/3;
    width: 95% !important;
  }

  input:not([type='checkbox']),
  textarea,
  select {
    font-size: 16px;
    padding: 6px;
    display: block;
    width: 100%;
    border: none;
    outline: none;
    transition: 0.3s;
    transition-property: left, top, font-size, color;
    resize: vertical;
    box-sizing: border-box;
    background-color: white;
    resize: vertical;
  }

  textarea {
    height: 120px;
    min-height: 80px;
    max-height: 300px;
  }

  label {
    left: 3px;
    top: -20px;
    font-size: 14px;
    color: #fff;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    transition: 0.2s ease all;
  }

  select + label {
    color: #333;
  }

  input + label,
  textarea + label {
    left: 10px;
    top: 6px;
    color: #999;
    font-size: 16px;
  }

  input:focus ~ label,
  input:not(:placeholder-shown) ~ label,
  textarea:focus ~ label,
  textarea:not(:placeholder-shown) ~ label {
    left: 3px;
    top: -20px;
    font-size: 14px;
    color: #fff;
  }

  &--dark {
    .form input:not([type='checkbox']),
    .form select,
    .form textarea {
      border: solid 1px $primaryColor;
    }

    input + label,
    textarea + label {
      color: #aaa;
    }

    input:focus ~ label,
    input:not(:placeholder-shown) ~ label,
    textarea:focus ~ label,
    textarea:not(:placeholder-shown) ~ label {
      color: #333;
    }
  }

  .g-recaptcha {
    @include margin-auto;
    width: 304px;
  }
}

// Wyszukiwarka dowolna
.search-form {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  background-color: $light;
  margin-bottom: 20px;
  color: white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px;
  border-radius: 5px;

  h3 {
    color: white;
    text-align: center;
    grid-column: 1/3;
    margin: 0;
    font-weight: 300;
    font-variant: small-caps;
    font-size: 1.4em;
  }

  .button {
    grid-column: 1/3;
    margin: 0 auto;
    margin-top: 10px;
  }

  .form__group {
    width: 100%;
  }

  select + label {
    color: #fff;
  }
}

.checkbox {
  padding: 3px;
  margin-bottom: 3px;
  position: relative;

  input {
    position: absolute;
    top: 3px;
    left: 12px;
    opacity: 0;
  }

  input:checked + label {
    &::after {
      opacity: 1;
    }

    &:hover::before {
      background-color: #ddd;
    }
  }

  label {
    padding: 5px;
    padding-left: 30px;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    position: relative;
    transition: 0.3s;

    &::before {
      content: '';
      left: 3px;
      height: 24px;
      width: 24px;
      background-color: #eee;
      border-radius: 3px;
      @include center('vertical');
      cursor: pointer;
      transition: 0.3s;
    }

    &::after {
      content: '';
      left: 3px;
      height: 24px;
      width: 24px;
      background-color: $primaryColor;
      background-image: url('/img/icons/check.svg');
      background-size: contain;
      background-position: center;
      border-radius: 3px;
      opacity: 0;
      @include center('vertical');
      cursor: pointer;
      transition: 0.3s;
    }

    &:hover::before {
      background-color: #ddd;
    }
  }

  input[disabled] + label::before {
    cursor: default;
  }

  input[disabled] + label::after {
    filter: grayscale(0.8) brightness(1.3);
    cursor: default;
  }

  &--radio label::before,
  &--radio label::after {
    border-radius: 50%;
    background-image: none;
  }

  &--radio label::after {
    transform-origin: center;
    transform: scale(0.6) translateY(-85%);
  }
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;

  & + label {
    display: inline-block;
    cursor: pointer;
    @include margin-auto;
  }

  &:focus + label,
  & + label:hover {
    background-color: $primaryColor * 1.15;
  }
}

.select {
  position: relative;

  &::after {
    content: '\f107';
    @include pseudo-fontawesome;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 8px;
    pointer-events: none;
    color: #a5a5a5;
  }

  option {
    color: #333;
    font-size: 0.9em;
    padding: 0;
  }
}

