.books .partners-grid__item {
  grid-template-columns: 120px 1fr;

  @media #{$phone} {
    grid-template-columns: 60px 1fr;

    img {
      align-self: auto;
    }
  }

  b {
    color: $primaryColor;
  }
}

.book-content {
  > img {
    float: left;
    width: 200px;
    margin-right: 10px;
  }

  b {
    color: $primaryColor;
  }

  @media #{$phone} {
    img {
      float: none;
      display: block;
      margin: 0 auto;
      max-width: 100%;
    }
  }
}
