//Lista ofert

.work {
  padding: 10px;
  border-bottom: solid 1px #ddd;
  display: grid;
  grid-template-columns: auto 1fr;
  text-decoration: none;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  transition: background-color 0.3s;

  &:hover {
    background-color: #f4f4f4;
  }

  &:first-of-type {
    border-top: solid 1px #ddd;
  }

  &:nth-last-of-type(2) {
    margin-bottom: 5px;
  }

  img {
    max-width: 120px;
    max-height: 60px;
    object-fit: contain;
    // border: solid 1px $primaryColor;
    margin-right: 10px;
    // background-color: white;

    @media screen and (max-width: 490px) {
      display: none;
    }
  }

  .work__content {
    h5 {
      font-size: 26px;
      margin: 0;
      display: inline-block;
      color: $primaryColor;
      width: 49%;
    }

    h6 {
      font-size: 12px;
      text-transform: uppercase;
      width: 50%;
      text-align: right;
      display: inline-block;
      color: #999;
      margin: 0;

      @media #{$phone} {
        text-align: left;
        position: relative;
        top: -4px;
      }
    }

    p {
      display: inline-block;
      width: calc(50% - 3px);
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      color: #666 !important;

      &:first-of-type {
        padding-left: 20px;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 20px;
          height: 18px;
          background-image: url(../img/icons/location.svg);
          opacity: 0.6;
          background-size: contain;
        }
      }

      &:last-of-type {
        color: #333 !important;
        text-align: right;
        font-size: 1.3em;
      }
    }

    @media screen and (max-width: 490px) {
      * {
        width: 100% !important;
        margin-bottom: 2px !important;
      }
    }
  }

  &--imgfree {
    display: flex;

    .work__content {
      width: 100%;
    }
  }

  &--small {
    display: flex;
    padding: 4px;
    margin-bottom: 0 !important;

    .work__content {
      width: 100%;
    }

    h5 {
      font-size: 18px !important;
    }

    h6 {
      font-size: 10px !important;
    }

    p {
      font-size: 14px !important;
    }
  }

  &--premium {
    h5 {
      box-sizing: border-box;
      position: relative;
      padding-left: 24px;
    }

    h5::before {
      content: '★';
      position: absolute;
      top: 0;
      left: 0;
      font-size: 14px;
      box-sizing: border-box;
      padding-top: 1px;
      width: 19px;
      height: 19px;
      text-align: center;
      border-radius: 50%;
      @include center('vertical');
      color: white;
      background-color: goldenrod;

      display: block;
    }
  }
}

.content-add + .work {
  border-top: solid 1px #ddd;
}

.work-list {
  padding: 10px;

  .content-add {
    margin: 5px auto;
  }
  .button {
    margin: 0 auto;
    margin-top: 10px;
  }
}

// Konkretna oferta
.oferta-pracy {
  padding: 10px;

  > .page-head {
    display: flex;
    justify-content: space-between;

    span {
      color: #666;
      font-weight: 700;
      height: 100%;
      font-size: 14px;
      margin-top: auto;
      margin-bottom: 2px;
      text-transform: uppercase;
    }
  }

  &--premium {
    > .page-head {
      box-sizing: border-box;
      position: relative;
      padding-left: 28px;
    }

    > .page-head::before {
      content: '★';
      position: absolute;
      top: 0;
      left: 0;
      font-size: 18px;
      box-sizing: border-box;
      padding-top: 1px;
      padding-left: 1px;
      width: 24px;
      height: 24px;
      text-align: center;
      border-radius: 50%;
      @include center('vertical');
      color: white;
      background-color: goldenrod;

      display: block;
    }
  }

  &__user {
    display: grid;
    grid-template-columns: 0;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      'img added'
      'img name'
      'img place';
    grid-gap: 8px;
    border-bottom: solid 2px #ddd;
    padding-bottom: 10px;
    position: relative;

    img {
      grid-area: img;
      width: 170px;
      max-height: 70px;
      object-fit: contain;
      // @include round-img(80px);
      // border: solid 1px $primaryColor;
    }

    small {
      grid-area: added;
    }

    h2 {
      grid-area: name;
      margin: 0;
      font-size: 1.5em;
    }

    p {
      grid-area: place;
      position: relative;
      padding-left: 20px;
      margin: 0;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 18px;
        background-image: url(../img/icons/location.svg);
        opacity: 0.6;
        background-size: contain;
      }
    }

    h4 {
      right: 10px;
      margin: 0;
      font-size: 1.3em;
      color: $secondaryColor;
      position: absolute;
      bottom: 10px;
      font-weight: $normalFont;
    }

    &--premium {
      grid-template-columns: 170px 1fr;
    }
  }

  &__section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;

    h3 {
      font-variant: small-caps;
      margin-bottom: 0;
      font-size: 1.5em;
      grid-column: 1/3;
    }

    p {
      margin: 4px 0;
    }

    &--contact span {
      font-size: 1.4em;
      letter-spacing: 1px;
      color: $primaryColor * 0.8;
    }
  }

  &__checkwrapper {
    display: flex;
    align-self: start;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;

    h5 {
      margin: 0;
      margin-top: 16px;
      width: 100%;
    }

    &--wide {
      grid-column: 1/3;
    }
  }

  &__check {
    margin: 3px;
    background-color: $primaryColor;
    color: white;
    border-radius: 5px;
    padding: 3px 6px;
    padding-left: 20px;
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 3px;
      width: 16px;
      height: 16px;
      background-image: url(../img/icons/check.svg);
      background-position: center;
      background-size: contain;
      filter: invert(0);
    }

    &--light {
      background-color: #eee;
      color: #333;

      &::before {
        filter: invert(1);
        opacity: 0.7;
      }
    }

    &--clear {
      padding-left: 6px;

      &::before {
        display: none;
      }
    }
  }
}

.mini-work {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  border-bottom: solid 1px #ccc;
  padding: 5px 10px;
  grid-gap: 3px;
  text-decoration: none;
  transition: background-color 0.3s;

  &--first {
    border-top: solid 1px #ccc;
  }

  &:hover {
    background-color: #eee;
  }

  p {
    margin: 0;
  }

  &__title {
    color: $primaryColor;
    font-size: 1.3em;
  }

  &__place {
    color: #aaa;
    font-size: 0.9em;
    padding-left: 20px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 18px;
      background-image: url(../img/icons/location.svg);
      opacity: 0.6;
      background-size: contain;
    }
  }

  &__type {
    color: #aaa;
    text-align: right;
    font-size: 12px;
    line-height: 23px;
    text-transform: uppercase;
  }

  &__price {
    color: #aaa;
    text-align: right;
    font-size: 1.1em;
    color: $secondaryColor;
  }
}

.grid-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.grid-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.grid-4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
