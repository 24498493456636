// Zestaw zdjęć
.igw {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  grid-auto-flow: dense;

  .he-ad {
    grid-column: 1/-1;
  }

  .center-add {
    grid-column: 1/4;
    margin-bottom: 10px;
  }

  &__item {
    text-decoration: none;
    position: relative;
    border-radius: 4px;
    border-radius: 6px;
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 6px 0;

    img {
      display: block;
      width: 100% !important;
      height: 220px;
      object-fit: cover;
      border-radius: 3px;
    }

    small {
      color: #666;
      display: block;
      text-align: center;
      transition: color 0.3s;
      font-size: 14px;
      padding: 3px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.3s;
    }

    &:hover {
      small {
        color: $primaryColor;
        background-color: #eee;
      }
    }

    &--16x9 {
      .aspect{
        padding-top: 56.25%;
        position: relative;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &--4x3 {
      .aspect{
        padding-top: 75%;
        position: relative;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  @media #{$mini_tablet} {
    grid-template-columns: 1fr 1fr;

    .center-add {
      grid-column: 1/3;
      margin-bottom: 10px;
    }
  }

  @media #{$phone} {
    grid-template-columns: 1fr;

    .center-add {
      grid-column: 1/2;
      margin-bottom: 10px;
    }
  }
}

.igw-row {
  img {
    width: 80%;
    display: block;
    border-radius: 3px;
    margin-bottom: 10px;
    box-shadow: 1px 1px 3px #777;
  }
}

// Konkretne zdjęcie
.gal-img {
  > img {
    width: 100%;
    border-radius: 5px;
  }

  .info {
    margin-top: -5px;
    p {
      margin: 3px 0;
    }
  }
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
  margin-bottom: 20px;
  margin-top: -10px;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.img-gallery::after {
  content: 'Galeria ' attr(data-count) ' zdjęć';
  position: absolute;
  top: 6px;
  right: 6px;
  background-color: #fff;
  box-shadow: 1px 1px 6px lighten($light, 30);
  color: $primaryColor;
  padding: 4px;
  border-radius: 4px;
  font-size: 12px;
}
