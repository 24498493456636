.profile-summary {
  display: flex;
  padding-top: 20px;

  $image-width: 140px;

  &__img {
    @include round-img($image-width);
    margin: 0 auto;
  }

  &__summary {
    width: calc(100% - #{$image-width } - 40px);
    height: $image-width;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // align-items: center;

    h1 {
      margin: 20px 0;
    }

    .sum-stats {
      width: 100%;
      background-color: #eee;
      border-top: solid 1px #aaa;
      border-bottom: solid 1px #aaa;
      border-left: solid 1px #aaa;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: auto;

      .button {
        margin-left: auto;
      }

      &__stat {
        display: flex;
        flex-direction: column;
        margin: 0 16px;

        span.title {
          font-variant: small-caps;
          color: $secondaryColor;
          font-size: 0.7rem;
        }

        span:not(.title) {
          font-weight: $boldFont;
        }
      }
    }
  }
}

.profile-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  padding: 16px;
  margin-top: 32px;

  .section {
    line-height: 1.2em;
  }

  .md-content * {
    margin: 0;
  }

  h2 {
    font-variant: small-caps;
    margin-bottom: 4px;
    margin-top: 24px;
  }
}
