// Artykuły

.article-set {
  padding: 10px;

  .button {
    width: 200px;
    margin: 10px auto;
  }

  .article-set__wrapper {
    width: 100%;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-flow: dense;
    grid-auto-rows: 190px;
    grid-gap: 10px;
    margin: 10px 0;

    &:nth-of-type(2n-1) .article:nth-of-type(5n),
    &:nth-of-type(2n) .article:nth-of-type(5n-4) {
      grid-column-end: span 2;
      grid-row-end: span 2;

      .article__title {
        font-size: 1.5em;
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
}

.article-list a {
  margin: 10px;
  font-size: 1em !important;
  height: 160px !important;
}

.articles {
  h3 {
    grid-column: 1/3;
    display: block;
    color: #888;
    text-decoration: none;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .article {
    display: block;
    min-height: 190px;
    position: relative;
    font-size: 1rem;
    overflow: hidden;

    &.sponsored::before {
      content: 'sponsorowane';
      position: absolute;
      top: 10px;
      left: 10px;
      background-color: $secondaryColor;
      text-transform: uppercase;
      color: white;
      font-size: 12px;
      padding: 2px;
      z-index: 4;

      @media #{$mini_tablet} {
        font-size: 10px;
      }
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 70%;
      width: 100%;
      z-index: 3;
      background: linear-gradient(transparent, #111);
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover;
      transition: transform 0.3s;
      position: absolute;
      top: 0;
      left: 0;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      &::after,
      &::before {
        @include pseudo-fontawesome;
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 4rem;
        transform: translate(-50%, -50%);
      }

      &::before {
        @include flex-center;
        // content: "\f03e";
        content: '';
        color: #fff;
        width: 100%;
        height: 100%;
        background-color: #ccc;
        z-index: 1;
      }

      // &::after {
      //   content: "\f715";
      //   color: firebrick;
      //   font-size: 4rem;
      //   z-index: 1;
      // }

      &::after {
        content: '';
        z-index: 1;
        width: 70%;
        height: 30%;
        top: 45%;
        background-size: contain;
        background-image: url('/img/logo.svg');
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    small {
      display: block;
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 12px !important;
      line-height: 12px;
      padding: 3px 5px;
      z-index: 3;
      background-color: white;
      color: $primaryColor !important;
      border-radius: 5px;
    }

    &__title {
      position: absolute;
      display: block;
      bottom: 0;
      left: 0;
      width: 100%;
      font-size: 1.1em;
      font-weight: 500;
      color: #fff;
      padding: 10px;
      padding-bottom: 0px;
      padding-top: 22px;
      box-sizing: border-box;
      margin: 0;
      text-indent: 0;
      transition: margin-bottom 0.3s;
      text-align: left;
      z-index: 5;
      max-height: calc(5.5em + 22px);
      line-height: 1.1em;
      overflow: hidden;
      margin-bottom: 10px;
    }

    &:hover {
      img {
        transform: scale(1.05);
      }

      p {
        margin-bottom: 15px;
      }
    }
  }
}

@media #{$mini-tablet} {
  .article-set__wrapper {
    grid-template-columns: 1fr 1fr !important;

    .article {
      grid-column-end: span 1 !important;
      grid-row-end: span 1 !important;

      p {
        font-size: 1em !important;
      }
    }
  }
}

@media #{$phone} {
  .article-set__wrapper {
    grid-template-columns: 1fr !important;
  }
}

// Konkretny artykuł
header.article {
  padding: 20px;
  height: 400px;
  margin-bottom: 5px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  &.sponsored::before {
    content: 'artykuł sponsorowany';
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: $secondaryColor;
    text-transform: uppercase;
    color: white;
    font-size: 12px;
    padding: 3px 6px;
    z-index: 4;

    @media #{$mini_tablet} {
      font-size: 10px;
    }
  }

  &::after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.85));
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  h1 {
    text-align: left;
    width: 100%;
    color: white;
    display: block;
    z-index: 2;
    position: relative;
    padding: 5px;
    font-size: 2.3em;
    letter-spacing: 2px;
    z-index: 1;
  }

  small {
    width: 90px;
    text-align: center;
    padding: 10px;
    color: white;
    border: solid 1px white;
    z-index: 2;
    word-break: keep-all;
  }

  @media #{$mini_tablet} {
    small {
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 6px;
    }
  }
}

main.article {
  p {
    margin: 22px 0;
    font-size: 18px;
    line-height: 22px;
  }

  * {
    white-space: normal;
  }

  a {
    color: $primaryColor;
  }

  ul,
  ol {
    font-size: 18px;
  }

  h2 {
    margin: 22px 0;
  }

  & > img {
    width: 100%;
  }

  .photo-author {
    padding: 4px 8px;
    display: block;
    color: #888;
  }

  article {
    padding-top: 8px;
    padding-left: 16px;
    padding-right: 8px;
  }
}

#disqus_thread {
  padding: 0 16px;

  a {
    color: $primaryColor !important;
  }
}

@media #{$tablet} {
  main.article article {
    padding: 0 10px;
  }
}

.tags {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 5px;

  &__item {
    display: inline-block;
    padding: 3px;
    background-color: #eee;
    border-radius: 3px;
    margin: 3px;
    color: $primaryColor;
    text-transform: uppercase;
    font-size: 12px;
  }
}

header.without-img {
  padding: 10px;
  margin-top: 24px;
  height: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &::after {
    display: none;
  }

  h1 {
    text-transform: none;
    color: $primaryColor;
    text-align: center;
  }

  small {
    color: #333;
    border-color: #333;
    margin-left: 10px;

    @media #{$mini_tablet} {
      position: static;
      margin: 0;
    }
  }

  img {
    position: static;
    width: auto;
    height: auto;
    max-width: 40%;
  }
}
