.cookie-alert {
  position: fixed;
  bottom: 0; left: 0;
  width: 100%;
  padding: 16px;
  background-color: #fff;
  z-index: 1000;
  box-shadow: 0 2px 6px #333;
  transition: transform 0.3s;
  box-sizing: border-box;

  &--hidden {
    transform: translateY(120%);
  }

  &__content,
  &__options {
    width: 800px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    @media #{$tablet} {
      width: 100%;
    }

    @media #{$phone} {
      align-items: flex-start;
    }
  }

  a {
    color: $primaryColor;
  }

  img {
    width: 64px;
    margin-right: 12px;

    @media #{$phone} {
      width: 32px;
    }
  }

  p {
    margin: 0;
    font-size: 0.8em;
    margin-top: 8px;
  }

  &__options {
    justify-content: space-between;
    margin-top: 12px;
    flex-wrap: wrap;
  }

  &__checkboxs-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .checkbox {
    transform: scale(0.8);
  }

  &__button {
    all: unset;
    background-color: $primaryColor;
    padding: 4px 18px;
    color: #fff !important;
    cursor: pointer;
    font-size: 0.8em;
    transition: background-color 0.3s;
    margin-left: auto;

    &:hover {
      background-color: lighten($primaryColor, 10);
    }
  }
}