// Index
.img-set {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  grid-gap: 8px;
  justify-content: center;
  margin: 10px 0;

  img {
    width: 100%;
    height: 100px;
    margin: 0 3px;
    object-fit: contain;
  }

  a {
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 200px;
    padding: 6px 0;
    @include margin-auto;
  }

  span {
    width: 100%;
    display: block;
    color: $primaryColor;
    text-align: center;
    width: 100%;
  }

  &--side {
    flex-direction: column;

    a {
      max-width: 100%;
    }

    img {
      width: auto;
      margin-bottom: 10px;
    }
  }
}

.companies-set {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  margin-bottom: 20px;

  &__item {
    padding: 6px;
    font-size: 18px;
    background-color: $primaryColor;
    text-decoration: none;
    text-align: center;
    color: white;
    font-variant: small-caps;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: 0.3s;

    img {
      width: 30px;
    }

    span:last-of-type {
      font-size: 13px;
      color: #ccc;
      padding-top: 3px;
    }

    &:hover {
      background-color: $primaryColor * 1.2;
    }
  }

  &:nth-of-type(even) {
    .companies-set__item {
      background-color: $secondaryColor;

      &:hover {
        background-color: $secondaryColor * 1.5;
      }
    }
  }

  @media #{$mini_tablet} {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media #{$phone} {
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
    margin-bottom: 10px;
  }

  @media #{$mini_phone} {
    grid-template-columns: 1fr;
  }
}

.presentation-box {
  box-sizing: border-box;
  width: 100%;
  transition: 0.3s;
  border-radius: 16px;
  padding: 6px 12px !important;

  &:hover {
    background-color: #f2f2f2;
  }

  img {
    max-width: 100%;
    object-fit: contain;
    object-position: center;
  }

  .noimage {
    height: 100px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    color: #fff;
    // border-radius: 16px;

    img {
      width: 60px;
      height: 60px;
      margin-bottom: 0;
      background-color: $secondaryColor;
      box-shadow: 4px 4px $primaryColor;
      padding: 8px 16px;
    }
  }
}

iframe.company-card {
  margin: 0 auto;
  width: 100%;
  height: 100%;
}

// Kategoria
.cdb-c-set {
  padding-top: 20px;

  &__item {
    padding: 5px;
    border-bottom: solid 1px #aaa;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'title img'
      'info img';
    grid-gap: 1px;

    a {
      margin: 0;
      grid-area: title;
      text-decoration: none;
      color: $primaryColor;
      font-size: 20px;
      font-weight: $boldFont;
      transition: 0.3s;

      &:hover {
        color: $primaryColor * 1.3;
      }
    }

    div {
      grid-area: info;
      p {
        margin: 0;
        color: #777;
      }
    }

    img {
      justify-self: end;
      max-height: 60px;
      max-width: 120px;
      object-fit: contain;
      grid-area: img;
      align-self: center;

      @media #{$phone} {
        height: 40px;
      }
    }

    &:first-of-type {
      border-top: solid 1px #aaa;
    }

    @media #{$phone} {
      grid-template-areas:
        'title title'
        'info img';
    }
  }
}

// Konkretna firma
.cdb-about {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;

  &__item {
    img {
      display: block;
      @include margin-auto;
    }

    h3 {
      color: $secondaryColor;
      font-size: 16px;
      font-variant: small-caps;
      margin: 0;
      margin-top: 3px;
    }

    a,
    p {
      font-size: 20px;
      color: $primaryColor;
      margin: 0;
    }

    a:hover::after {
      border-color: $primaryColor;
    }
  }
}
