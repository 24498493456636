// Kontakt
.contact {
  p {
    text-align: center;
    color: #666;

    &.bold {
      color: $primaryColor;
      font-weight: $boldFont;
    }

    &:last-of-type {
      margin-bottom: 5px;
    }
  }

  .row {
    @extend .flexi;
    width: 50%;
    @include margin-auto;
    justify-content: space-around;
    font-size: 18px;

    @media #{$mini_tablet} {
      width: 100%;
    }

    @media #{$mini_phone} {
      flex-direction: column;

      .icon:last-of-type {
        margin-top: 10px;
      }
    }
  }

  .icon {
    padding-left: 30px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      width: 24px;
      height: 24px;
      background-position: center;
      background-size: cover;
      opacity: 0.6;

      @include center('vertical');
    }

    &.nip::before {
      background-image: url('/img/icons/business_dark.svg');
    }

    &.phone::before {
      background-image: url('/img/icons/phone.svg');
    }
  }

  .form {
    &__group {
      @include margin-auto;
    }

    input,
    textarea {
      border: solid 1px $primaryColor;
    }

    .button {
      @include margin-auto;
    }
  }
}

// Partnerzy i giełdy przy okazji (i reklamy)
.partners-grid {
  &__item {
    display: grid;
    grid-template-columns: 400px 1fr;
    grid-gap: 15px;
    border-bottom: dashed 1px #ccc;
    padding: 10px;

    >div:first-child {
      grid-column: 1/3;
    }

    img {
      display: block;
      max-width: 100%;
      max-height: 300px;
      @include margin-auto;
      align-self: center;
    }

    h3 > a {
      color: inherit;
      text-decoration: none;
      transition: color 0.3s;

      &:hover {
        color: $primaryColor * 1.4;
      }
    }

    small {
      color: #777777;
    }

    div > a {
      position: relative;
      color: $primaryColor;
      text-decoration: none;
      padding-left: 30px;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        width: 24px;
        height: 24px;
        background-position: center;
        background-size: cover;
        opacity: 0.6;
        background-image: url('/img/icons/globe.svg');
        @include center('vertical');
      }
    }

    // @media #{$}{
    //   grid-template-columns: 300px 1fr;
    // }

    @media #{$tablet} {
      grid-template-columns: 200px 1fr;
    }

    @media #{$mini_tablet} {
      grid-template-columns: 1fr;
      grid-gap: 0;
    }

    .noimage {
      background-color: #eee;
      height: 100px;
      border-radius: 8px;
      position: relative;

      &::before,
      &::after {
        @include pseudo-fontawesome;
        content: 'd';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 3em;
      }

      &::before {
        content: '\f03e';
        color: $light;
      }

      &::after {
        content: '\f715';
        color: firebrick;
      }
    }
  }

  @media #{$mini_tablet} {
    &--adds &__item {
      margin-bottom: 40px;
    }

    &--adds .page-head {
      border-color: #f8f8f8;
    }
  }
}
