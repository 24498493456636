.flexi {
  display: flex;
  justify-content: center;
  align-items: center;
}

// Wielki tytuł jak w wywiadach
h1.grand-title {
  text-transform: none;
  color: white;
  background-color: $light;
  padding: 10px;
  text-align: center;
  border-radius: 10px;
  font-weight: $normalFont;
}

// Link z podkreśleniem
a.nice {
  color: inherit;
  text-decoration: none;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 3px;
    border-bottom: solid 1px transparent;
    transition: bottom 0.3s, border-color 0.3s;
  }
  &:hover::after {
    border-color: white;
    bottom: 0;
  }
}

// Link z backgroundem
a.art {
  color: $primaryColor;
  text-decoration: none;
  position: relative;
  z-index: 2;
  font-weight: 700;
  transition: color 0.5s, background-color 0.5s;
  padding: 2px;

  &:hover {
    color: #fff;
    background-color: $primaryColor;
  }
}

// Link z graficzką
a.img-link {
  display: inline-block;
  color: $primaryColor;
  position: relative;
  padding: 3px;
  padding-left: 26px;
  margin-right: 10px;
  text-decoration: none;
  transition: color 0.3s;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    width: 22px;
    height: 22px;
    background-position: center;
    background-size: cover;
    opacity: 0.6;
    background-image: url('/img/icons/globe.svg');
    @include center('vertical');
    opacity: 0.5;
    transition: opacity 0.3s;
  }

  &:hover {
    color: $primaryColor * 0.5;

    &::before {
      opacity: 0.9;
    }
  }
}

.img-link-wrapper {
  padding-left: 10px;
  display: flex;
  flex-direction: column;

  a.img-link {
    margin-bottom: 3px;
    margin-right: 0;
  }

  &--books {
    .img-link::before {
      background-image: url('/img/icons/book.svg');
    }
  }

  &--photo {
    .img-link::before {
      background-image: url('/img/icons/photo.svg');
    }
  }

  &--film {
    .img-link::before {
      background-image: url('/img/icons/film.svg');
    }
  }
}

// Nagłówki
h1 {
  color: $primaryColor;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0;
}

h2 {
  color: $primaryColor;
  letter-spacing: 1px;
  margin: 10px 0;
}

h3 {
  color: $primaryColor;

  &.page-head {
    font-size: 1.4em;
    margin-top: 5px;
    margin-bottom: 15px;
  }

  &.page-head::after {
    width: 40px;
  }
}

.gray-right {
  color: #888;
  display: block;
  text-align: right;
}

// Główny header
.page-head {
  display: block;
  margin-bottom: 10px;
  border-bottom: solid 3px #ccc;
  text-transform: none;
  font-variant: small-caps;
  position: relative;

  a {
    color: $primaryColor;
    text-decoration: none;
    transition: 0.3s;

    &:hover {
      color: $primaryColor * 1.5;
    }
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 80px;
    height: 3px;
    background-color: $primaryColor;
  }

  &--center {
    text-align: center;

    &::after {
      @include center('horizontal');
    }
  }
}

// Basic grid
.basic-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  margin-bottom: 10px;

  > *:last-of-type {
    grid-column: 1/3;
  }

  &__img {
    position: relative;

    @media #{$mini_tablet} {
      height: 100px;
    }

    img {
      display: block;
      @include center('both');
      min-width: 40%;
      min-height: 40%;
      max-width: 90%;
      max-height: 90%;
      object-fit: contain;
    }
  }

  .page-head {
    margin-top: 10px;
  }

  p {
    margin: 4px 0;
  }

  .nice {
    color: $primaryColor;
  }

  .nice:hover::after {
    border-color: $primaryColor;
  }

  @media #{$mini_tablet} {
    grid-template-columns: 1fr;

    > *:last-of-type {
      grid-column: 1/2;
    }
  }
}

// Header bocznej sekcji
.section-header {
  width: calc(100% - 10px);
  background-color: $secondaryColor;
  color: white;
  font-weight: 300;
  text-align: center;
  padding: 10px;
  box-shadow: 10px 10px 0px $primaryColor;
  box-sizing: border-box;
  margin: 15px auto;
  transform: translateX(-8px);

  @media #{$tablet} {
    width: 65%;
  }

  @media #{$phone} {
    width: 90%;
  }
}

// Przycisk
.button {
  background-color: $primaryColor;
  padding: 8px 12px;
  color: #fff;
  text-decoration: none;
  display: block;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 0 5px;
  text-align: center;
  border: 0;

  &:hover {
    background-color: $primaryColor * 0.8;
  }

  &--light {
    background-color: #eee;
    color: #777;

    &:hover {
      background-color: #ccc;
    }
  }

  &--dark {
    background-color: $secondaryColor;

    &:hover {
      background-color: $secondaryColor * 1.7;
    }
  }

  &--center {
    @include margin-auto;
  }

  &--small {
    font-size: 0.8em;
    padding: 4px 8px;
  }
}

// Basic padding
.basic {
  padding: 10px;

  &--wide {
    grid-column: 1/3;
  }
}

.mobile-disabled {
  @media #{$tablet} {
    display: none;
  }
}
