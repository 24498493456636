nav#main-nav {
  top: 0px;
  position: sticky;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: solid 2px #e5e5e5;
  border-bottom: solid 2px #e5e5e5;
  background-color: #fff;
  z-index: 1000;

  & > a {
    display: block;
  }

  @media #{$desktop} {
    .main-nav__logo {
      position: absolute;
    }
  }

  img {
    height: 36px;
    display: block;
  }
  ol {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
    margin: 0 16px;

    a,
    span {
      display: block;
      text-decoration: none;
      color: $primaryColor;
      padding: 14px;
      transition: background-color 0.3s;

      &:hover {
        background-color: #f3f3f3;
      }
    }
  }
  .buttins {
    display: flex;
  }

  .loggedin {
    @extend .flexi;
    height: 46px;
    text-decoration: none;
    transition: 0.3s;
    padding: 0 5px;
    margin-left: -10px;
    margin-right: 0px;

    &:hover {
      background-color: #f3f3f3;
    }

    img {
      display: block;
      @include round-img(36px);
    }

    h6 {
      margin: 0;
      margin-left: 4px;
      color: $primaryColor;
      font-size: 0.9em;
    }

    & + .buttins .button {
      transform: scale(0.8);
    }
  }

  #menu-trigger {
    display: block;
    position: absolute;
    top: 7px;
    right: 6px;
    padding: 0;
    background-color: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    opacity: 0.6;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.8;
    }
    img {
      padding: 0;
      height: 36px;
    }
  }
}

@media #{$desktop} {
  nav#main-nav {
    flex-direction: column;
    align-items: flex-start;
    max-height: 49px;
    overflow: hidden;

    img {
      padding: 5px;
    }

    #menu-trigger {
      display: block;
      position: absolute;
      top: 7px;
      right: 6px;
      padding: 0;
      background-color: transparent;
      border: 0;
      outline: 0;

      img {
        padding: 0;
        height: 36px;
      }
    }

    ol {
      flex-direction: column;
      text-align: left;
      width: 100%;
      margin: 0;
      margin-bottom: 5px;
      opacity: 0;
      visibility: hidden;

      li {
        width: 100%;
        box-sizing: border-box;
      }
    }

    .buttins {
      width: 100%;
      justify-content: center;
      align-items: center;
      opacity: 0;
      visibility: hidden;
    }

    &.online {
      max-height: 400px;
      padding-bottom: 5px;

      ol,
      .buttins {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
