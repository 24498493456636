.wywTygodnia {
  padding: 10px;

  h2.page-head {
    font-size: 2em;
    margin-top: 0;
  }

  article {
    margin-top: 15px;
    padding: 0 20px;
    font-size: 18px;
    text-align: justify;

    .bold {
      font-weight: $boldFont;
    }
  }

  small {
    text-align: right;
    display: block;
    color: $primaryColor;
    margin-bottom: 10px;
    text-align: center;

    &::before {
      content: '~ ';
    }
  }

  p:first-of-type {
    margin-top: 3px;
  }

  &__pic {
    // @include round-img(200px);
    max-width: 200px;
    float: left;
    margin-right: 10px;

    img {
      width: 100%;
    }
  }
}

.spec-list {
  margin: 10px 0;

  &__item {
    display: block;
    color: $secondaryColor;
    text-decoration: none;
    border-bottom: solid 2px #ddd;
    padding: 6px 0;
    transition: 0.3s;

    &:hover {
      background-color: #f4f4f4;
    }

    &:first-of-type {
      border-top: solid 2px #ddd;
    }

    img {
      display: block;
      @include round-img(64px);
      @include margin-auto;
    }

    p {
      margin: 0;
      text-align: center;
      color: $primaryColor;
    }
  }
}

.interview-item {
  display: block;
  text-decoration: none;
  padding: 16px;
  transition: 0.3s;
  border-bottom: dashed 1px #aaa;

  @media #{$phone} {
    padding: 16px 8px;
  }

  &:hover {
    background-color: #f5f5f5;
  }

  h2 {
    font-variant: small-caps;
    margin: 0;
    margin-bottom: 4px;
  }

  p {
    color: #333;
    margin: 0;
  }

  span {
    color: $primaryColor;
    text-decoration: underline;
    padding-left: 10px;
    transition: 0.3s;

    &:hover {
      color: lighten($primaryColor, 10);
    }
  }
}
