.message-page {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  height: 100%;

  .respond-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.messages-wrapper {
  height: 60vh;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 0 8px;

  small {
    display: block;
    text-align: center;
    color: #888;
    margin: 8px 0;
  }

  &--classic {
    height: auto;
    min-height: 300px;
    overflow: hidden;
  }
}

.message {
  display: flex;
  margin: 12px 0;

  &__user {
    position: relative;

    &::before {
      content: attr(data-username);
      position: absolute;
      bottom: 50%;
      left: 50%;
      white-space: nowrap;
      background-color: $light;
      color: #fff;
      transform: translate(-50%, 0);
      border-radius: 4px;
      font-size: 0.8em;
      padding: 4px;
      transition: 0.3s;
      visibility: hidden;
      opacity: 0;
    }

    &:hover::before {
      visibility: visible;
      opacity: 1;
      bottom: calc(100% + 5px);
    }

    img {
      @include round-img(32px);
    }
  }

  &__content {
    background-color: lighten($light, 30);
    padding: 8px;
    border-radius: 8px;
    margin: 0 12px;
    color: #333;
    max-width: 75%;
    position: relative;

    @media #{$tablet} {
      max-width: 100%;
      margin: 0;
      padding: 8px 0;
    }

    &::before {
      position: absolute;
      content: '';
      top: 8px;
      right: 100%;
      border: solid 8px transparent;
      border-right-color: lighten($light, 30);
    }
  }

  &--user {
    flex-direction: row-reverse;
  }

  &--user &__content {
    background-color: lighten($primaryColor, 10);

    &::before {
      border-left-color: lighten($primaryColor, 10);
      border-right-color: transparent;
      right: auto;
      left: 100%;
    }
  }

  &--classic {
    flex-direction: column;
  }

  &--classic &__user::before {
    left: 72px;
    visibility: visible;
    opacity: 1;
    bottom: calc(50% - 12px) !important;
    background-color: transparent;
    color: $primaryColor;
    font-size: 1em;
  }

  &--classic &__content {
    background-color: transparent;
    overflow: hidden;
    height: auto;

    &::before {
      display: none;
    }
  }
}

.message-respond {
  width: 100%;
  display: flex;
  justify-content: stretch;
  align-items: center;
  background-color: lighten($light, 10);
  padding: 8px;
  border-radius: 8px;
  margin-top: auto;
  box-sizing: border-box;

  &__textarea {
    width: 100%;
    border-radius: 8px;
    padding: 8px;
    margin-right: 8px;
    border: 0;
  }

  &__send {
    display: block;
    margin: 0;
    height: 54px;
  }
}