// Główna strona forum
.forum-index {
  padding: 10px;

  @media #{$mini_phone} {
    padding: 0 3px;
  }

  > h3.page-head {
    margin-bottom: 10px;
    margin-top: 30px;

    &:first-of-type {
      margin-top: 10px;
    }
  }

  small {
    margin-top: -10px;
    padding-top: 3px;
    margin-bottom: 10px;
    display: block;
    color: #666;
  }
}

.topic {
  display: grid;
  grid-template-columns: 32px 1fr 50px 120px;
  grid-template-rows: 1fr;
  justify-content: center;
  align-items: center;
  padding: 8px 3px;
  border-bottom: solid 1px #aaa;

  &:first-of-type {
    border-top: solid 1px #aaa;
  }

  &:nth-of-type(2n + 3) {
    background-color: #f2f2f2;
  }

  &__more-in-categ {
    @extend .button;
    @extend .button--light;

    display: inline-block;
    margin-top: 12px;
    padding: 4px 8px;
    font-size: 0.8em;
  }

  img {
    display: block;
    width: 18px;
    height: 18px;
    margin-left: 4px;
  }

  a {
    color: $primaryColor;
    text-decoration: none;

    &:hover {
      color: $primaryColor * 1.5;
    }
  }

  p {
    margin: 0;
    text-align: center;
    color: #666;
    font-size: 0.9em;
  }

  &--header {
    grid-template-columns: 1fr 50px 120px;
    background-color: $light;

    p:first-of-type {
      text-align: left;
      padding-left: 32px;
    }

    p {
      font-size: 0.8em;
      color: white;
    }
  }

  &--settings {
    grid-template-columns: 32px 1fr 50px 120px 100px;

    .button {
      padding: 5px;
      font-size: 12px;
      @include margin-auto;
      filter: brightness(0.95);
    }
  }

  &--settings-header {
    grid-template-columns: 1fr 50px 120px 100px;
  }

  @media #{$mini_phone} {
    img {
      display: none;
    }
    p:last-of-type {
      display: none;
    }
    grid-template-columns: 1fr 50px;

    &--header {
      p {
        padding: 0 !important;
      }
    }
  }
}

// Konkretny temat
.thread {
  padding: 10px;
  grid-column: 1/3;
  background-color: #f9f9f9;
  height: 100%;

  .page-head {
    margin-bottom: 20px;
    font-size: 2.5em;
  }

  &__header {
    padding: 10px 0;
    grid-gap: 10px;
    display: grid;
    grid-template-columns: 1fr 300px;
    grid-template-areas:
      'title sub'
      'started respond';

    .button {
      align-self: center;
      position: relative;

      &--light {
        width: 180px;
        margin-left: auto;
        transition: 0.3s;

        &.subscribed {
          color: #aaa;
        }
      }

      &.subscribe-button::before {
        position: absolute;
        top: 6px;
        right: calc(100% + 0px);
        content: '';
        background-color: grey;
        color: #fff;
        padding: 4px;
        border-radius: 4px;
        font-size: 0.8em;
        width: 150px;
        transition: right 0.3s ease, opacity 0.3s ease, top 0.3s ease;
        opacity: 0;
        visibility: hidden;

        @media #{$phone} {
          position: fixed;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      &.subscribe-button::after {
        position: absolute;
        top: 12px;
        right: calc(100% - 10px);
        content: '';
        border: solid 5px transparent;
        border-left-color: grey;
        transition: right 0.3s ease, opacity 0.3s ease;
        opacity: 0;
        visibility: hidden;

        @media #{$phone} {
          display: none;
        }
      }

      &.subscribe-button.subscribe-button--unlogged::before {
        right: calc(100% + 8px);
        content: 'Musisz być zalogowany!';
        background-color: orange;
        opacity: 1;
        visibility: visible;

        @media #{$phone} {
          top: 100px;
        }
      }

      &.subscribe-button.subscribe-button--unlogged::after {
        border-left-color: orange;
        right: calc(100% - 2px);
        opacity: 1;
        visibility: visible;

        @media #{$phone} {
          top: 100px;
        }
      }

      &.subscribe-button.subscribe-button--error::before {
        right: calc(100% + 8px);
        content: 'Błąd serwera!';
        background-color: firebrick;
        opacity: 1;
        visibility: visible;
      }

      &.subscribe-button.subscribe-button--error::after {
        border-left-color: firebrick;
        right: calc(100% - 2px);
        opacity: 1;
        visibility: visible;
      }
    }

    h1 {
      text-transform: none;
      font-weight: $normalFont;
      grid-area: title;
    }

    p {
      margin-top: 0;
      font-style: italic;
      grid-area: started;
    }

    @media #{$mini_tablet} {
      grid-template-columns: 1fr;
      grid-template-areas:
        'title' 'started'
        'sub' 'respond';
    }
  }
}

.thread__about {
  background-color: $light;
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 20px;
  border-radius: 10px;

  @media #{$tablet} {
    grid-template-columns: 1fr;
  }

  p {
    color: white;
    margin: 0;
    padding: 3px 0;
  }

  > .button {
    margin-left: auto;
  }

  .pages {
    align-self: center;
    justify-self: end;
    display: inline-flex;

    @media #{$tablet} {
      justify-self: center;
    }

    .button {
      background-color: lighten($light, 16);
      font-size: 12px;
      width: 64px;
      padding: 6px;
      margin: 0 3px;
      transition: 0.3s;
      white-space: nowrap;

      &.num {
        width: 16px;
      }

      &:hover {
        background-color: lighten($light, 20);
      }
    }

    input,
    &__current {
      background-color: #fff;
      font-size: 12px;
      padding: 6px;
      width: 16px;
      border-radius: 7px;
      margin: 0 3px;
      border: 0;
      text-align: center;
    }

    &--small {
      .button {
        margin: 0 1px;
        padding: 6px 4px;
      }
    }
  }

  &--bottom {
    margin-bottom: 10px;

    .pages {
      justify-self: start;
    }
  }

  &--index {
    border-radius: 0;
    padding: 6px;
    margin-top: 10px;
    margin-bottom: 0px;
  }
}

.post {
  position: relative;
  padding: 20px 10px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-gap: 10px;
  background-color: white;
  border: dashed 1px #bbb;

  --color: #{#888};

  &__user {
    text-align: center;

    h2 {
      margin: 5px 0;
      color: $primaryColor;
      word-break: break-word;
    }

    img {
      display: block;
      @include round-img(140px);
      @include margin-auto;
    }

    .tag {
      background-color: #fff;
      color: var(--color);
      border: solid 1px var(--color);
      border-radius: 5px;
      padding: 5px;
      font-weight: $boldFont;
      width: 60%;
      font-size: 14px;
      @include margin-auto;
      margin-top: 10px;
    }

    @media #{$mini_tablet} {
      display: grid;
      grid-template-columns: 40px 1fr;
      grid-template-areas: 'img name' 'img title';
      justify-content: center;
      align-items: center;
      grid-gap: 0 6px;

      h2 {
        font-size: 18px;
        grid-area: name;
        text-align: left;
        margin: 0;
      }

      img {
        @include round-img(40px);
        margin: 0;
        grid-area: img;
      }

      .tag {
        width: 100px;
        grid-area: title;
        font-size: 0.6em;
        font-style: italic;
        color: var(--color);
        background-color: transparent !important;
        margin: 0;
        text-align: left;
        padding: 0;
        border: 0 !important;
      }
    }
  }

  @media #{$mini_tablet} {
    &--default &__user {
      grid-template-columns: 1fr;
      grid-gap: 0;
      grid-template-areas: 
        "name"
        "title";
    }
  }

  &__content * {
    word-break: break-word;
  }

  &__content a {
    color: $primaryColor;
  }

  &__wrapper {
    padding-bottom: 25px;

    small {
      position: absolute;
      top: 10px;
      right: 10px;
      display: block;
      text-align: right;
      color: #666;
      font-size: 12px;
    }
  }

  &__buttons {
    position: absolute;
    bottom: 5px;
    right: -34px;
    display: flex;
    width: 260px;
    justify-content: flex-end;
    align-items: center;
    transform: scale(0.7);

    .button {
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }
    }
  }

  &__footer {
    display: block;
    color: #888;
    padding: 8px 0;
    font-size: 0.9em;
    margin-top: 16px;
    border-top: solid 2px #eee;

    &:empty {
      padding: 0;
      margin: 0;
      border: none;
    }
  }

  &--user {
    --color: #{$primaryColor};
  }

  &--admin {
    --color: #b22222;
    border: solid 1px var(--color);

    h2 { color: var(--color); }
  }

  &--moderator {
    --color: #11806a;
    border: solid 1px var(--color);

    h2 { color: var(--color); }
  }

  &--moderator .tag,
  &--admin .tag {
    background-color: var(--color);
    color: #fff;
    border: 0;
  }


  @media #{$mini_tablet} {
    display: block;
    padding: 10px;
  }
}

blockquote {
  border: solid 1px #eee;
  border-left: solid 2px #555;
  margin-left: 20px;
  box-sizing: border-box;
  padding-left: 1px;

  > div:first-of-type {
    background-color: #f2f2f2;
    border-bottom: solid 1px #eee;
    padding: 5px;
    font-weight: $boldFont;
    width: 100%;
    box-sizing: border-box;
  }

  p {
    margin: 0;
    padding: 10px;
  }

  @media #{$mini_tablet} {
    padding: 5px;
    margin-left: 10px;
    margin-right: 0;
    margin-bottom: 0;
    padding-top: 30px;
    display: inline-block;
    box-sizing: border-box;

    > div:first-of-type {
      font-size: 0.8em;
    }
  }
}

.create-post {
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-gap: 5px;
  font-size: 1.2em;

  @media #{$tablet} {
    grid-template-columns: 1fr;
  }

  &__topic {
    font-weight: $normalFont !important;
    color: $secondaryColor !important;
  }

  hr {
    grid-column: 1/3;
    border: 0;
    border-top: dashed 1px #ccc;
    width: 100%;

    @media #{$tablet} {
      grid-column: 1/2;
    }
  }

  input,
  select {
    border: 0;
    border-radius: 0;
    border-bottom: solid 2px #ccc;
    outline: none;
    width: 90%;
    padding: 5px;
    transition: 0.3s;
    box-sizing: border-box;

    @media #{$tablet} {
      width: 100%;
    }

    &:focus {
      border-color: $primaryColor;
    }
  }

  select {
    font-size: 0.8rem;
  }

  textarea {
    width: 90%;
    padding: 5px;
    resize: vertical;
    border: solid 1px #ccc;
    border-radius: 3px;
    min-height: 140px;
    height: 400px;
    box-sizing: border-box;

    @media #{$tablet} {
      width: 100%;
    }
  }

  span {
    color: $primaryColor;
    font-weight: $boldFont;
    margin-top: 6px;
  }

  .g-recaptcha {
    @include margin-auto;
    grid-column: 1/3;

    @media #{$tablet} {
      grid-column: 1/2;
    }
  }

  .button {
    @include margin-auto;
    grid-column: 1/3;
    margin-top: 10px;

    @media #{$tablet} {
      grid-column: 1/2;
    }
  }
}

.forum-no-threads {
  background-color: orange;
  padding: 4px;
  color: #ffffff;
  border-radius: 3px;
}

.report-modal__bg {
  position: fixed;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background: rgba($secondaryColor, 0.3);
  z-index: 10000;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;

  &--active {
    visibility: visible;
    opacity: 1;
  }
}

.report-modal {
  position: fixed;
  z-index: 10000;
  top: 50%; left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  background-color: #fff;
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;

  @media #{$mini_tablet} {
    width: 70%;
  }

  @media #{$phone} {
    width: 90%;
  }

  &--active {
    visibility: visible;
    opacity: 1;
  }

  .page-head {
    width: 100%;
  }

  .form__group {
    width: 100%;
    border-bottom: solid 2px #ddd;

    &:focus {
      border-color: $primaryColor;
    }
  }

  .button {
    display: inline-block;
  }
}