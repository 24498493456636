// Margin auto
@mixin margin-auto {
  margin: {
    left: auto;
    right: auto;
  }
}

// Centrowanie absolutne
@mixin center($position) {
  position: absolute;

  @if $position == 'vertical' {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  } @else if $position == 'horizontal' {
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translate(-50%);
  } @else if $position == 'both' {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

// Zaokrąglone zdjęcie
@mixin round-img($size) {
  width: $size;
  height: $size;
  border-radius: 50%;
  object-fit: cover;
}

@mixin pseudo-fontawesome {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
}
