.login-basic {
  @extend .flexi;
  flex-direction: column;

  .page-head {
    width: 80%;
  }
}

.error:not(:empty) {
  color: #e00000;
  font-style: italic;
  margin-top: 10px;
  text-align: center;

  & + * {
    margin-top: 10px;
  }
}

.login-form {

  .form__group,
  .form-like {
    @include margin-auto;
  }

  input:not([type='checkbox']),
  textarea,
  select {
    border-bottom: solid 2px #ddd;
    border-radius: 0;
    transition: 0.3s;

    &:focus {
      border-color: $primaryColor;
    }
  }

  .button {
    @include margin-auto;
  }

  .splited {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0;
    transition: color 0.3s;
  }

  .nice {
    color: #999;

    &:hover {
      color: #777;
    }

    &:hover::after {
      border-color: #777;
    }
  }
}

.register-form {
  small {
    display: block;
    text-align: center;
  }

  .login-form {
    @extend .basic-grid;

    .form__group,
    .form-like {
      width: 90%;
      margin-top: 20px;
    }

    small {
      text-align: left;
    }

    .long {
      grid-column: 1/3;
      width: 95%;
      @include margin-auto;

      .page-head {
        width: 100%;
      }
    }
  }

  a {
    color: $primaryColor;
    text-decoration: none;
    transition: 0.3s;

    &:hover {
      color: $primaryColor * 1.5;
    }
  }

  .summary {
    margin-top: 0 !important;
  }
}
