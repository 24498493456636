// Base
.exp-quest-list {
  &__quest {
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px #ddd;
    padding: 3px;
    margin-bottom: 5px;
    align-items: center;
    transition: 0.3s;
    text-decoration: none;

    img {
      grid-area: img;
      @include round-img(70px);
    }

    p {
      width: 90%;
      box-sizing: border-box;
      padding-left: 6px;
      color: $primaryColor;
      font-weight: $normalFont;
      text-decoration: none;
      margin: 0;
      overflow: hidden;
      position: relative;
      line-height: 1.2em;
      max-height: 3.6em;
      text-align: justify;
      margin-right: -1em;
      padding-right: 1em;

      &::before {
        display: block;
        content: 'Pytanie: ';
        font-weight: 400;
        font-variant: small-caps;
        color: $secondaryColor;
      }
    }

    &--article p {
      color: $secondaryColor;
      font-weight: 700;

      &::before {
        display: none;
      }
    }

    &:hover {
      background-color: #eee;
    }

    &--clear {
      padding: 5px;
    }
  }
}

.experts-add-question {
  padding: 4px;
  box-sizing: border-box;

  label {
    margin-top: 6px;
    margin-bottom: 1px;
  }
  * {
    width: 100% !important;
  }

  .button {
    width: 100px !important;
    @include margin-auto;
    margin-top: 10px;
  }
}

// Eksperci (główna)
.eksperci {
  padding: 10px;

  &__ekspert {
    display: grid;
    grid-template-columns: 160px 1fr;
    grid-template-rows: auto auto 1fr;
    grid-template-areas:
      'img name'
      'img text'
      'img button';
    grid-gap: 10px;
    margin-bottom: 10px;
    align-items: start;
    justify-content: start;

    &:first-of-type {
      margin-top: 20px;
    }

    h2 {
      margin: 0;
      grid-area: name;
    }

    p {
      margin: 0;
      grid-area: text;
    }

    img {
      display: block;
      grid-area: img;
      @include round-img(160px);
    }

    .button {
      grid-area: button;
      width: 200px;
      margin-left: auto;
    }

    @media #{$phone} {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto auto auto;
      grid-template-areas: 'img' 'name' 'text' 'button';
      margin-bottom: 20px;
      grid-gap: 5px;

      img {
        width: 120px;
        @include margin-auto;
      }

      h2 {
        text-align: center;
      }

      p {
        text-align: center;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }

      .button {
        @include margin-auto;
        width: 100%;
        box-sizing: border-box;
      }
    }
  }
}

// Konkretny ekspert
.ekspert {
  padding: 10px;

  > img {
    display: block;
    @include margin-auto;
    @include round-img(380px);
  }

  > p {
    text-align: justify;
  }

  .title {
    font-style: italic;
    font-weight: $normalFont;
    color: #555;
    margin-top: 0;
    text-align: center;
  }

  @media #{$phone} {
    > img {
      @include round-img(200px);
    }
  }
}

// Porada
.porada-eksperta {
  padding: 10px;

  @media #{$phone} {
    padding: 4px;
  }

  small {
    display: block;
    text-align: right;
    color: #777;
  }

  article {
    margin-top: 15px;
    padding: 0 20px;
    font-size: 18px;
    text-align: justify;

    @media #{$phone} {
      padding: 0px;
    }

    .bold {
      font-weight: $boldFont;
    }
  }
}

.side-img {
  @include round-img(160px);
  @include margin-auto;
}

.side-link {
  color: $primaryColor;
  font-variant: small-caps;
  text-align: center;
  text-decoration: none;
  display: block;
  font-size: 1.6em;
  transition: 0.3s;

  &:hover {
    color: $primaryColor * 1.5;
  }
}

.auto-side-img {
  @media #{$mini-phone} {
    width: 100%;

    img {
      width: 100%;
    }
  }
}

.expert-answer,
.expert-question {
  &::before {
    content: 'Odpowiedź: ';
    padding: 4px;
    color: $secondaryColor;
    display: block;
    text-align: center;
    font-variant: small-caps;
    font-size: 20px;
    font-weight: 400;
  }
}

.expert-question {
  color: $primaryColor;
  text-align: left;
  font-weight: 300;
  text-transform: none;
  display: block;
  font-size: 1.7em;

  &::before {
    content: 'Pytanie: ';
  }
}

.asked-by {
  font-size: 1rem;
  color: #333 !important;
  font-style: italic;
}
