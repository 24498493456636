// Strona główna

.content.index {
  display: block;
}

main.index {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: start;
  height: 100%;
  padding-bottom: 20px;
  grid-gap: 5px;

  grid-template-areas:
    'slider slider slider'
    'wide-pa wide-pa wide-pa'
    'articles articles box1'
    'articles articles wywiad'
    'articles articles forum'
    'articles articles firmy'
    'ekspert widebox1 widebox1'
    'ekspert praca praca'
    'box2 praca praca'
    'kalendarium praca praca'
    'articles2 articles2 articles2'
    'partners partners partners';

  @media #{$tablet} {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'slider slider'
      'wide-pa wide-pa'
      'box1 box1'
      'articles articles'
      'widebox1 widebox1'
      'ekspert forum'
      'firmy wywiad'
      'praca praca'
      'box2 kalendarium'
      'articles2 articles2'
      'partners partners';
  }

  @media #{$mini-tablet} {
    grid-template-columns: 1fr;
    grid-template-areas:
      'slider'
      'wide-pa'
      'box1'
      'articles'
      'widebox1'
      'ekspert'
      'forum'
      'wywiad'
      'firmy'
      'praca'
      'box2'
      'kalendarium'
      'articles2'
      'partners';
  }

  .button {
    width: 180px;
    margin: 0 auto;
  }

  .section-header {
    width: calc(100% - 40px);
    width: 300px;
    margin-top: 0;
  }

  section {
    box-sizing: border-box;
    padding: 10px;

    h4 {
      color: $primaryColor;
      font-size: 1.3em;
      margin: 0;
    }

    small {
      color: #999;
      font-size: 0.6em;
      font-weight: 500;
      display: block;
      text-align: center;
    }

    p {
      margin: 0;
      margin-bottom: 5px;
    }

    &.centered {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
}

.slider {
  grid-area: slider;
  font-size: 16px;
  margin: 0;
  width: 100%;
  display: block;
  box-sizing: border-box;
  // height: 400px;
  min-height: 300px;
  position: relative;
  padding: 0;
  overflow: hidden;
  background-color: $secondaryColor;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .slid-child {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: left 0.8s;

    &.sponsored::before {
      content: 'sponsorowane';
      position: absolute;
      top: 10px;
      left: 10px;
      background-color: $secondaryColor;
      text-transform: uppercase;
      color: white;
      font-size: 12px;
      padding: 2px;
      z-index: 4;

      @media #{$mini_tablet} {
        font-size: 10px;
      }
    }

    &:first-of-type {
      z-index: 10;
    }

    small {
      font-size: 14px;
      line-height: 14px;
      color: $primaryColor !important;
      padding: 3px 5px;
      background-color: white;
      border-radius: 5px;
      position: absolute;
      font-weight: 500;
      padding-top: 4px;
      display: block;
      right: 10px;
      top: -20px;
      // transform: translateY(-50%);

      @media #{$mini_tablet} {
        top: -20px;
        right: 10px;
      }
    }

    a {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      color: white;
      text-decoration: none;
      padding: 100px 0 20px 30px;
      background: linear-gradient(transparent, #111);
      box-sizing: border-box;

      h3 {
        font-size: 2em;
        transition: margin-bottom 0.3s;
        margin: 0;
        position: relative;
        padding-right: 10px;
        color: white;

        @media #{$tablet} {
          font-size: 1.4em;
        }

        @media #{$mini_phone} {
          font-size: 1em;
        }
      }

      p {
        color: #aaa;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
      }

      &::before {
        content: '';
        position: absolute;
        display: block;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 400px;
      }

      &:hover h3 {
        margin-bottom: 3px;
      }

      &.sponsored::before {
        content: 'sponsorowane';
        position: absolute;
        top: 10px;
        left: 10px;
        background-color: $secondaryColor;
        text-transform: uppercase;
        color: white;
        font-size: 12px;
        padding: 2px;
        z-index: 1;
      }
    }
  }

  .bar {
    background-color: $secondaryColor;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    z-index: 11;

    span {
      display: block;
      height: 100%;
      background-color: $primaryColor;

      width: 0;

      animation: slider linear;
      animation-iteration-count: infinite;
    }
  }
}

@keyframes slider {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.company {
  display: block;
  margin: 0 auto;
  text-align: center;
  max-width: 100%;

  img {
    min-width: 40%;
    min-height: 60px;
    max-width: 100%;
    max-height: 140px;
    object-fit: contain;
  }
}

.wide-panel {
  grid-area: wide-pa;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 20px;
  padding: 20px !important;
  background-color: $light;
  margin: 0;
  height: 500px;
  margin-top: -5px;

  & > *:first-child {
    grid-column: 1/3;
    grid-row: 1/3;
    // font-size: 1.6em !important;
  }

  .right-module {
    grid-column: 3/4;
    grid-row: 1/3;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    >div {
      width: 100%;
    }

    h3 {
      text-align: center;
      font-variant: small-caps;
      color: #888;
      font-size: 1.4em;
      margin: 0;
      margin-top: 5px;
    }

    ul {
      list-style: none;
      padding: 0;
      margin-top: 0;

      a {
        color: #bbb;
        text-decoration: none;
        width: 100%;
        display: block;
        padding: 4px 8px;
        box-sizing: border-box;
        transition: background-color 0.3s;
        border-radius: 4px;
        margin: 0;
        position: relative;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 360px;

        &::before {
          content: '\002022 ';
          padding-right: 6px;
          color: $secondaryColor;
        }

        &:hover {
          background-color: #7773;

          span {
            background-color: white;
          }
        }

        @media #{$phone} {
          max-width: calc(100vw - 16px);
        }
      }
    }

    a span {
      color: #777;
      font-size: 13px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 5px;
      color: $primaryColor * 0.8;
      background-color: #aaa;
      border-radius: 3px;
      padding: 2px;
      transition: background-color 0.3s;

      line-height: 12px;
      font-size: 12px;
    }

    input {
      // Newsletter
      margin: 0 auto;
      display: block;
      margin-bottom: 5px;
    }
  }

  @media #{$tablet} {
    grid-template-columns: 1fr 1fr 1fr;

    & > *:first-child {
      grid-column: 1/3;
    }

    .right-module {
      grid-column: 3/4;
    }
  }

  @media #{$mini_tablet} {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto 1fr;
    height: auto;

    & > *:first-child {
      grid-column: 1/2;
      grid-row: 1/2;
    }

    .right-module {
      grid-row: 2/3;
      grid-column: 1/2;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
    }
  }

  @media #{$phone} {
    grid-template-rows: auto auto auto 1fr;
    height: auto;
    grid-gap: 8px;
    padding: 8px !important;

    .right-module {
      ol,
      li {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}

.article-box {
  grid-area: articles;
}

.article-box2 {
  grid-area: articles2;
  // display: none;
}

.wywiad-tygodnia {
  grid-area: wywiad;

  .wywiad {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    box-sizing: border-box;
    width: 100%;

    img {
      border-radius: 50%;
      width: 120px;
      height: 120px;
      margin-left: 10px;
      object-fit: cover;
    }

    a {
      display: block;
      width: calc(100% - 140px);
      margin-left: 10px;
      text-decoration: none;
      color: $secondaryColor;

      h3 {
        margin-top: 10px;
        margin-bottom: 5px;
        color: $primaryColor;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    @media #{$mini_phone} {
      flex-direction: column;

      a {
        width: 100%;
      }
    }
  }
}

.porady-ekspertow {
  grid-area: ekspert;

  .porady {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .eksperci {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
    }

    img {
      @include round-img(90px);
      margin-left: -26px;

      &:first-of-type {
        margin: 0;
      }
    }

    &__list {
      margin: 0;
      padding: 0;
      list-style: none;

      li a {
        display: block;
        padding: 5px;
        border: solid 1px #ccc;
        margin: 5px;
        text-align: center;
        font-weight: 400;
        color: $primaryColor;
        text-decoration: none;
        font-size: 1em;
        transition: background-color 0.3s;

        &:hover {
          background-color: #eee;
        }
      }
    }

    @media #{$mini_phone} {
      img {
        width: 25vw !important;
        height: 25vw !important;
      }
    }
  }
}

.praca {
  grid-area: praca;

  .button {
    margin-top: 10px !important;
  }
}

.forum-list {
  grid-area: forum;

  .forum__item {
    text-decoration: none;
    color: #333;
    padding: 5px;
    border-radius: 5px;
    transition: background-color 0.3s;
    display: block;
    margin: 5px auto;
    width: 95%;
    position: relative;
    padding-right: 64px;
    box-sizing: border-box;

    &::after {
      content: attr(data-response);
      color: #666;
      font-size: 14px;
      line-height: 14px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 5px;
      width: 40px;
      height: 16px;
      padding-right: 20px;
      background-image: url(../img/icons/forum.svg);
      background-position: center right;
      background-size: contain;
      background-repeat: no-repeat;
      text-align: right;
      z-index: 1;
    }

    &:hover {
      background-color: #f0f0f0;
    }
  }

  &--longer {
    > .forum__item {
      width: 100%;
      margin: 4px 0;
    }
  }
}

.kalendarium {
  grid-area: kalendarium;

  .kalend .elem {
    border-bottom: solid 1px #aaa;
    color: #333;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 4px;
    width: 90%;
    margin: 0 auto;
    text-decoration: none;
    transition: background-color 0.3s;

    p {
      margin: 0;
      width: 100%;

      &:last-child {
        color: $primaryColor;
        width: auto;
        text-align: right;
      }
    }

    &:hover {
      background-color: #eee;
    }

    &:first-child {
      border-top: solid 1px #aaa;
    }
  }
}
.facebook {
  grid-area: facebook;
}
.firma {
  grid-area: firmy;
}
.box1 {
  padding-top: 32px !important;
  grid-area: box1;
  flex-direction: column;

  @media #{$tablet} {
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media #{$phone} {
    iframe {
      width: 100%;
    }
  }
}
.box2 {
  grid-area: box2;
  flex-direction: column;
}
.box3 {
  grid-area: box3;
  flex-direction: column;
}
.widebox1 {
  grid-area: widebox1;
}

.partners {
  grid-area: partners;
  width: 100%;

  ::-webkit-scrollbar {
    height: 5px;
    background-color: #eee;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $primaryColor;
  }

  &__content {
    width: 100%;
    display: flex;
    overflow-x: scroll;
    box-sizing: border-box;

    @media #{$mini_tablet} {
      width: calc(100vw - 20px) !important;
    }

    img {
      min-width: 180px;
      width: 180px;
      height: 120px;
      object-fit: contain;
      margin: 0 16px;
    }
  }
}
