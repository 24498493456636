.statute {
  font-size: 18px;

  a {
    color: $primaryColor;
  }

  >p {
    text-align: center;
  }

  > h2 {
    text-align: center;
    font-weight: $normalFont;
  }

  > ol {
    list-style-type: none;
    padding-left: 20px;
    font-size: 1.2em;
    font-weight: $boldFont;
    counter-reset: list-counter;

    > li {
      counter-increment: list-counter;
      position: relative;
      padding-left: 30px;

      p {
        font-weight: 500;
        margin-top: 0;
        font-size: 1.1rem;
      }

      &::before {
        content: counter(list-counter) '§ ';
        position: absolute;
        top: 0;
        left: -5px;
        color: $primaryColor;
      }
    }

    ol {
      //Inner list
      font-size: 0.8em;
      padding-left: 20px;
      list-style-type: decimal;
      font-weight: $normalFont;

      li {
        margin-bottom: 16px;
      }
    }
  }

  h3 {
    font-size: inherit;
    font-weight: $boldFont;
    letter-spacing: 2px;
    color: $primaryColor;
    font-variant: small-caps;
  }
}

@media (max-width: 760px) {
  .statute {
    font-size: 16px;
  }
  .statute ol {
    padding-left: 5px;
  }
  .statute ol ol {
    padding-left: 10px;
  }
}
