.err404 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  padding: 16px;

  @media #{$phone} {
    min-height: 70vh;
  }

  img {
    width: 300px;
    position: fixed;
    top: 16px;
    left: 50%;
    transform: translateX(-50%);

    @media #{$phone} {
      width: 70%;
    }
  }

  h1 {
    font-size: 10em;
    line-height: 0.8em;
    font-weight: 300;
    margin: 0;

    @media #{$phone} {
      font-size: 6em;
    }
  }

  h2 {
    font-variant: small-caps;
    margin: 0;
    font-weight: 400;
  }

  p {
    margin-top: 32px;
    text-align: center;
  }
}
