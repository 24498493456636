body {
  font-family: 'Lato', sans-serif;
  margin: 0;
  font-size: 16px;
  overflow-x: hidden;
  background-color: #f2f2f2;
  /*! Background pattern from Subtle Patterns */
  background-image: url(../img/greyzz.png);
  position: relative;
  right: 0;
  color: #333;
  font-weight: 400;
  min-height: 100vh;

  &.moved {
    overflow: hidden;
    padding-right: 16px;

    @media #{$phone} {
      padding: 0;
    }
  }
}

img {
  max-width: 100%;
}

iframe {
  display: block;
  margin: 10px auto;

  @media (max-width: 300px) {
    width: 100%;
  }
}

.content {
  min-height: calc(100vh - 338px);
  background-color: #fff;
  width: $cont_width;
  margin: 0 auto;
  box-shadow: 1px 0px 4px #ddd;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 320px;
  justify-content: start;
  align-items: start;

  & > .long {
    grid-column: 1/3;
  }

  > section img {
    display: block;
    @include margin-auto;
  }

  @media #{$wide_screen} {
    width: calc(100% - 360px);
    box-sizing: border-box;
  }

  @media #{$desktop} {
    width: 100%;
  }

  @media #{$tablet} {
    display: block;
  }
}

.flex {
  display: flex;
  justify-content: space-between;
}

main {
  box-sizing: border-box;
  min-height: 100%;
}

section {
  padding: 10px;
  height: 100%;

  ul.primary-list {
    list-style: none;
    padding: 0;

    li {
      margin: 5px 0;
      padding-left: 30px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: 16px;
        top: 8px;
        width: 5px;
        height: 5px;
        background-color: $primaryColor;
      }

      &:first-of-type a {
        font-weight: bold;
      }
    }

    a {
      color: $secondaryColor;
      text-decoration: none;
      transition: color 0.3s;

      &:hover {
        color: $primaryColor;
        text-decoration: underline;
      }
    }
  }
}


.big-square-button {
  padding: 32px;
  background-color: $primaryColor;
  color: #fff !important;
  text-align: center;
  margin: 12px;
  transition: 0.3s;
  font-size: 2em;
  font-variant: small-caps;

  &:hover {
    background-color: lighten($primaryColor, 10);
  }
}