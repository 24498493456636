.search-results {
  padding-bottom: 32px;

  .marked {
    background-color: rgb(241, 224, 148);
    font-weight: 900;
    padding: 2px;
  }
}

.search-article {
  display: flex;
  text-decoration: none;
  color: #333;
  padding: 12px 0;
  border-bottom: dashed #ccc 1px;
  transition: 0.3s;
  position: relative;

  .tag {
    position: absolute;
    bottom: 8px;
    right: 8px;
    padding: 4px 6px;
    background-color: $secondaryColor;
    color: #fff;
    font-variant: small-caps;
    font-size: 0.8em;
    border-radius: 6px;
    letter-spacing: 1px;
  }

  @media #{$mini_tablet} {
    flex-direction: column;

    > div:first-of-type {
      justify-content: center;
      align-items: center;
    }
  }

  &:first-child {
    border-top: dashed #ccc 1px;
  }

  img {
    display: block;
    width: 120px;
    height: auto;
    object-fit: contain;
    margin-right: 16px;
  }

  h2 {
    margin-top: 0;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
  }

  small i {
    color: $primaryColor;
    padding-right: 4px;
  }

  &:hover {
    background-color: #fafafa;
  }
}

.search-expert {
  @extend .search-article;

  h2 {
    font-size: 1em;
  }

  img {
    @include round-img(120px);
  }
}

.search-results .work {
  width: 100%;
  border-bottom: dashed #ccc 1px;
  box-sizing: border-box;

  &:first-child {
    border-top: dashed #ccc 1px;
  }
}

.search-topic {
  @extend .search-article;

  > div:first-of-type {
    justify-content: flex-start;
  }

  img {
    @include round-img(64px);
  }

  h2 {
    font-size: 1.2rem;
    margin-bottom: 8px;
  }

  small {
    margin-bottom: 8px;
    color: #888;

    span {
      padding: 0 8px;
    }
  }

  p {
    margin: 0;
  }
}

.search-event {
  @extend .search-article;

  h2 {
    font-size: 1.4rem;
  }

  small {
    color: #888;

    span {
      padding: 0 8px;
    }
  }

  .tag {
    @media #{$mini_tablet} {
      display: none;
    }
  }
}
